:root {
  --green: #094;
  --yellow: #490;
  --blue: #049;
  --red: #904;
  --purple: #409;
  --orange: #940;
  --black: #004;
  --white: #999;
}

.App {
  text-align: center;
}